import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/layout.js';
import styles from '../styles/menu.module.css';
import { graphql } from 'gatsby';
import { isRedirect } from '@reach/router';

const Menu = ({ data }) => {
  const sections = [
    { title: 'Happy Hour', subheader: 'Happy Hour is daily from 4:00 PM to 6:30 PM(Dine In Only)' },
    { title: 'House Specials', subheader: 'Check daily for house specials' }
    

  ];

  return (
    <Layout>
      <Helmet>
        <title>River City Drafthouse</title>
        <meta
          name='River City Drafthouse Happy Hour and House Specials Menu'
          content='River City Drafthouse Happy Hour and House Specials Menu'
        />
      </Helmet>
      <div className={styles.container}>
        {sections.map(section => {
          return (
            <section className={styles.menuSection}>
              <header className={styles.sectionHeader}>
                <h1 className={styles.sectionTitle}>{section.title}</h1>
                <h3 className={styles.sectionSubheader}>{section.subheader}</h3>
              </header>
              {data.allMarkdownRemark.edges.map(element => {
                const {
                  title,
                  comboNumber,
                  price,
                  smallPrice,
                  description,
                  category
                } = element.node.frontmatter;
                if (category === section.title && section.title !== 'Drafthouse Combo') {
                  return (
                    <div className={styles.menuItem}>
                      <span className={styles.itemHeader}>
                        <h1 className={styles.itemName}>{title}</h1>
                        {/*smallPrice ? <p className={styles.price}>M: {smallPrice}</p> : ""*/}
                        {/*<p className={styles.price}>L: {price}</p>*/}
                        <p className={styles.price}>{price}</p>
                      </span>
                      {description ? (
                        <p className={styles.description}>{description}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                } else if (
                  category === section.title &&
                  section.title === 'Drafthouse Combo'
                ) {
                  return (
                    <div className={styles.comboItem}>
                      <span className={styles.itemHeader}>
                        <h1 className={styles.itemName}>
                          {/*comboNumber*/} {title}
                        </h1>
                        {/* {smallPrice ? (
                          <p className={styles.price}>L: {smallPrice}</p>
                        ) : (
                          ''
                        )} */}
                        {/* <p className={styles.price}>D: {price}</p> */}
                      </span>
                    </div>
                  );
                }
              })}
            </section>
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query specialQuery {
    allMarkdownRemark(sort: { fields: frontmatter___title, order: ASC }) {
      edges {
        node {
          frontmatter {
            title
            path
            category
            comboNumber
            price
            smallPrice
            description
          }
        }
      }
    }
  }
`;
export default Menu;
